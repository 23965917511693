@import url(https://use.typekit.net/eiq5ijl.css);
body {
  margin: 0;
  font-family: degular-display, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

.app-wrapper {
  background: #000;
  color: #ddd;
  position: relative;
  height: 100vh;
  width: 100vw;
}

.button-bg {
  background-color: #000;
  border-radius: 1.5em;
  display: none;
  height: 3em;
  opacity: 0.2;
  position: fixed;
  width: 3em;
  top: 39vh;
}
@media screen and (max-width: 768px) {
  .button-bg {
    display: inline-block;
  }
}

.back-button-bg {
  left: 1em;
}

.next-button-bg {
  right: 1em;
}

button {
  background: transparent;
  border: none;
  color: #444;
  cursor: pointer;
  font-family: degular-display, sans-serif;
  font-size: 5em;
  height: 100vh;
  position: fixed;
  top: 0;
  width: 50vw;
}
@media screen and (max-width: 768px) {
  button {
    color: #fff;
    font-size: 2em;
    padding-bottom: 15vh;
  }
}

button:hover {
  color: #ddd;
}
@media screen and (max-width: 768px) {
  button:hover {
    color: #fff;
  }
}

button:focus {
  outline: none;
}

.back-button {
  left: 0;
  padding-right: 20vw;
}
@media screen and (max-width: 768px) {
  .back-button {
    padding-right: 35vw;
  }
}

.next-button {
  right: 0;
  padding-left: 20vw;
}
@media screen and (max-width: 768px) {
  .next-button {
    padding-left: 35vw;
  }
}

.slide-outer-wrapper {
  display: flex;
  justify-content: center;
  width: 100vw;
}

.slide-inner-wrapper {
  display: flex;
  flex-direction: column;
  max-height: 100vh;
  max-height: calc(1vh * 100);
  max-height: calc(var(--vh, 1vh) * 100);
}

img {
  max-height: calc(100vh - 120px);
  max-width: 100vw;
}

@media screen and (max-width: 768px) {
.slide-text-wrapper {
  padding: 0 3vw;
}
}

/* TYPOGRAPHY */

.slide-number {
  color: #999;
  float: right;
  font-family: degular-text, sans-serif;
  font-size: 0.4em;
  font-weight: 500;
  padding-top: 0.4em;
}

h2 {
  font-size: 2.5em;
  font-weight: 700;
  margin-bottom: 0;
  margin-top: 0.5em;
}

h3 {
  font-family: degular-text, sans-serif;
  font-size: 1em;
  font-weight: 500;
  margin-top: 0;
}
